import * as React from "react"
import { Helmet } from "react-helmet"
import { Contact } from "../../components/contact"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SEO from "../../components/seo"

export default function FSD220320 () {

    return <>
        <SEO
            title="Senior Full Stack Developer | Remote | NZ"
            description="Come work with us! Lets change the future of dairy farming"
        />
        <Header />
        <section className="mt-32 p-8 max-w-3xl m-auto">
            <div className="mb-8"><a href="/careers">{`<`} Back to Careers</a></div>
            <h2>Senior Full Stack Developer</h2>
            <div className="text-sm">Remote, NZ</div>
            <div className="mt-4">
                <p>
                    Farmote is a smart, innovative pasture monitoring system that combines satellite-based multispectral technology with accurate on-ground Motes to take the guesswork out of pasture and grazing management.
                </p>
                <p className="mt-4">Farmote System’s revolutionary combination of data from satellites in space and Motes on the ground, provides precise daily pasture measurements and invaluable insights through a mobile-friendly web platform.</p>
            </div>
            <div className="mt-4">
                <h3>About this role</h3>
                <p>In this role you'll</p>
                <ul className="list-disc list-outside ml-8">
                    <li>Provide software design expertise to the development team and business as a whole</li>
                    <li>Build robust, maintainable, scalable and secure web-based software for an Agricultural IoT solution</li>
                    <li>Work with a diverse team of technical experts to deliver project requirements in an open and constructive team environment</li>
                    <li>Foster a culture of passion for developing elegant technical solutions which make a measurable difference</li>
                </ul>
            </div>
            <div className="mt-4">
                <h3>What we need</h3>
                <ul className="list-disc list-outside ml-8">
                    <li>5+ years of software development experience</li>
                    <li>Good programming skills with TypeScript/Javascript</li>
                    <li>Solid experience with NodeJS backend or Go</li>
                    <li>Decent experience with ReactJS or any other front-end frameworks</li>
                    <li>You prefer unit tests over manually testing your code</li>
                </ul>
            </div>
            <div className="mt-4">
                <h3>Bonus Skills</h3>
                <ul className="list-disc list-outside ml-8">
                    <li>Worked with Google Cloud Platform</li>
                    <li>CI/CD tools and processes</li>
                    <li>Experience with D3.js or other charting libraries</li>
                    <li>Know functional programming and/or written code in F#</li>
                    <li>Graph databases (Dgraph), Docker, Kubernetes, ELK Stack, Flutter - you can tell we use all cool technologies</li>
                </ul>
            </div>
            <div className="mt-4">
                <h3>Benefits</h3>
                <ul className="list-disc list-outside ml-8">
                    <li>Competitive salary</li>
                    <li>Work from home flexibility</li>
                    <li>Cutting-edge technologies</li>
                    <li>Training budget</li>
                    <li>Work alongside some very smart people</li>
                </ul>
            </div>
            <div className="mt-4 bg-gray-100 p-4 text-secondary text-center">
                <p>If you're keen please forward your CV to <a href="mailto:info@farmote.com?subject=JOB Enquiry (FSD220320)" target="_blank">info@farmote.com</a></p>
            </div>
        </section>
        <Contact/>
        <Footer/>
    </>
}